import type { State } from '../../interfaces/State';
import type {
  StreakInfoState,
  StreakWeekItem,
} from '../reducers/streaks/types';
import { StreakInfoType } from '../reducers/streaks/types';

export const selectStreakInfoReady = (state: State): boolean => {
  const streakInfo: StreakInfoState = state.get('streakInfo');

  return streakInfo.type === StreakInfoType.Known;
};

export const selectStreakDailyXp = (state: State): number | undefined => {
  const streakInfo: StreakInfoState = state.get('streakInfo');

  if (streakInfo.type === StreakInfoType.Unknown) {
    return undefined;
  }

  return streakInfo.dailyXp;
};

export const selectStreakDays = (state: State): number | undefined => {
  const streakInfo: StreakInfoState = state.get('streakInfo');

  if (streakInfo.type === StreakInfoType.Unknown) {
    return undefined;
  }

  return streakInfo.current.streak.days;
};

export const selectStreakDailyGoalMetInSession = (state: State): boolean => {
  const streakInfo: StreakInfoState = state.get('streakInfo');

  if (streakInfo.type === StreakInfoType.Unknown) {
    return false;
  }

  return streakInfo.streakGoalMetInSession;
};

export const selectStreakFreezesAwardedInSession = (state: State): number => {
  const streakInfo: StreakInfoState = state.get('streakInfo');

  if (streakInfo.type === StreakInfoType.Unknown) {
    return 0;
  }

  return streakInfo.freezesAwardedInSession;
};

export const selectDailyStreakScreenVisible = (state: State): boolean => {
  const streakInfo: StreakInfoState = state.get('streakInfo');

  if (streakInfo.type === StreakInfoType.Unknown) {
    return false;
  }

  return streakInfo.screenVisible;
};

export const selectStreakDailyGoalMet = (state: State): boolean => {
  const streakInfo: StreakInfoState = state.get('streakInfo');

  if (streakInfo.type === StreakInfoType.Unknown) {
    return false;
  }

  return streakInfo.current.streak_goal_met;
};

export const selectStreakDailyGoal = (state: State): number | undefined => {
  const streakInfo: StreakInfoState = state.get('streakInfo');

  if (streakInfo.type === StreakInfoType.Unknown) {
    return undefined;
  }

  return streakInfo.streakGoal;
};

export const selectStreakIncrementedAt = (state: State): string | undefined => {
  const streakInfo: StreakInfoState = state.get('streakInfo');

  if (streakInfo.type === StreakInfoType.Unknown) {
    return undefined;
  }

  return streakInfo.current.streak.last_incremented_at;
};

export const selectStreakDeadline = (state: State): number | undefined => {
  const streakInfo: StreakInfoState = state.get('streakInfo');

  if (streakInfo.type === StreakInfoType.Unknown) {
    return undefined;
  }

  return Date.parse(streakInfo.current.streak_deadline);
};

export const selectStreakWeekView = (state: State): StreakWeekItem[] => {
  const streakInfo: StreakInfoState = state.get('streakInfo');

  if (streakInfo.type === StreakInfoType.Unknown) {
    return [];
  }

  return streakInfo.current.streak_week_view;
};
